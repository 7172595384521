<template>
  <div class="infra-policies">
    <CyAlert
      theme="error"
      :content="errors"/>
    <CyDataTableYdApi
      id="cy-infra-policies-table"
      ref="cyDataTable"
      :fetch-available="{ keyPath: 'infraPolicies' }"
      :bulk="hasBulkModeEnabled"
      :headers="$static.headers"
      :link-builder="getLinkTarget"
      :searchable-fields="$static.searchableFields"
      :filters="$static.filters"
      :sorts="$static.sorts"
      key-field="id">
      <template #table-cmp-header-actions="{ selected }">
        <template v-if="!_.isEmpty(selected)">
          <CyDevBtn
            :wrap-dev-text="false"
            class="my-0 mr-2"
            @click.native="setMissingOwners(selected)">
            Set missing owner
          </CyDevBtn>

          <CyButton
            v-if="!_.isEmpty(selected)"
            theme="error"
            icon="delete"
            @click="openDeleteModal(selected)">
            {{ $tc('deleteInfraPolicyBtn', selected.length, { nb: selected.length }) }}
          </CyButton>
        </template>
        <CyButton
          v-else
          v-has-rights-to="'CreateInfraPolicy'"
          icon="add"
          :to="{ name: 'newInfraPolicy' }">
          {{ $t('addInfraPolicyBtn') }}
        </CyButton>

        <!-- Delete modal -->
        <CyModal
          v-if="showDeleteModal"
          :header-title="$tc('confirmDeleteTitle', toDelete.length)"
          :action-btn-func="onDelete"
          :action-btn-text="$tc('deleteInfraPolicyBtn', toDelete.length, { nb: toDelete.length })"
          :cancel-btn-func="closeDeleteModal"
          modal-type="delete">
          <p>
            {{ $t('forms.cannotBeUndone') }}
            <span v-html="$tc('areYouSure', toDelete.length, { item: toDelete[0].name })"/>
          </p>
          <ul class="items-to-delete">
            <div
              v-for="{ canonical, name, description, severity, enabled } of toDelete"
              :key="canonical">
              <div class="d-flex">
                <v-col cols="8">
                  <h3>
                    {{ name }}
                    <v-chip
                      x-small
                      :class="`infra-policies__item--${severity}`">
                      {{ severity }}
                    </v-chip>
                  </h3>
                  <span class="infra-policies__item-description">{{ description }}</span>
                </v-col>

                <v-col cols="4">
                  <v-switch
                    class="mt-0"
                    disabled
                    :input-value="enabled"
                    :label="enabled ? $t('enabled') : $t('disabled')"
                    color="secondary"
                    hide-details/>
                </v-col>
              </div>
            </div>
          </ul>
        </CyModal>
      </template>

      <template #table-cmp-body-row="{ props: { item } }">
        <td>
          <div class="d-flex infra-policies__item mt-4">
            <h3>{{ item.name }}</h3>
            <p>
              <v-chip
                x-small
                :class="[`infra-policies__item--${item.severity}`, 'body-2']">
                {{ item.severity }}
              </v-chip>
            </p>
            <p class="infra-policies__item-description">
              {{ item.description }}
            </p>
          </div>
        </td>

        <td>
          <CyFormsAssignOwner
            v-if="canUpdateOwner(item)"
            :errors="errors"
            :form-content="item"
            :action-btn-func="assignNewOwner"
            sm/>
          <CyButton
            v-else
            :to="{
              name: 'member',
              params: {
                id: item.owner.id,
                backRouteTo: 'infraPolicies',
              },
            }"
            :disabled="!canGoToMember(item)"
            :readonly="!canGoToMember(item)"
            theme="grey"
            variant="tertiary"
            sm
            member-link
            @click.stop>
            <CyMember
              :member="item.owner"
              simple
              sm/>
          </CyButton>
        </td>

        <td class="infra-policies__item-status justify-end">
          <v-switch
            :input-value="item.enabled"
            :label="item.enabled ? $t('enabled') : $t('disabled')"
            :disabled="!$cycloid.permissions.canDisplay('UpdateInfraPolicy', item.canonical)"
            color="secondary"
            hide-details
            @click.prevent.stop="toggleInfraPolicyStatus(item)"/>
        </td>
      </template>
    </CyDataTableYdApi>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import CyDataTableYdApi from '@/components/data-table-yd-api.vue'
import { constructBreadcrumb, displayName, getMissingOwnerObject, hasNoOwner } from '@/utils/helpers'

export default {
  name: 'CyPageInfraPolicies',
  components: {
    CyDataTableYdApi,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.infraPolicies'), [
      {
        label: this.$t('routes.securitySection'),
        name: 'securitySection',
      },
    ])
  },
  header () {
    return {
      title: this.$t('routes.securitySection'),
      description: {
        text: this.$t('routes.securitySectionDescription'),
      },
    }
  },
  data: () => ({
    showDeleteModal: false,
    toDelete: [],
  }),
  computed: {
    $static () {
      return {
        headers: [
          {
            text: this.$t('title'),
            value: 'name',
            align: 'left',
          },
          {
            text: this.$t('owner'),
            align: 'left',
            value: 'owner',
            sort: (a, b) => displayName(a).localeCompare(displayName(b)),
          },
          {
            text: this.$t('forms.status'),
            value: 'enabled',
            align: 'right',
          },
        ],
        searchableFields: [
          {
            name: 'name',
            label: this.$t('forms.fieldName'),
          },
          {
            name: 'owner',
            label: this.$t('owner'),
            filterFunction: displayName,
          },
        ],
        filters: [
          {
            queryParams: ['infra_policy_severity'],
            search: false,
            type: 'select',
            label: _.upperFirst(this.$t('severities.severity')),
            items: _.map(['advisory', 'warning', 'critical'], (value) => ({
              title: _.upperFirst(this.$t(`severities.${value}`)),
              key: 'infra_policy_severity',
              value,
            })),
          },
          {
            queryParams: ['infra_policy_created_at[gt]', 'infra_policy_created_at[lt]'],
            type: 'dateTimeRange',
            format: 'YYYY-MM-DD',
            dateTimeRangeOptions: [
              {
                label: this.$tc('forms.field.timeRangeLastDays', 1),
                value: '1d',
              },
              {
                label: this.$tc('forms.field.timeRangeLastDays', 3, { days: 3 }),
                value: '3d',
              },
              {
                label: this.$t('forms.field.timeRangeLastWeek'),
                value: '1w',
              },
              {
                label: this.$tc('forms.field.timeRangeLastMonths', 1),
                value: '1M',
              },
              {
                label: this.$t('forms.field.timeRangeLastYear'),
                value: '1y',
              },
            ],
            label: this.$t('created'),
            tagOptions: {
              translations: {
                'infra_policy_created_at[gt]': 'createdAfter',
                'infra_policy_created_at[lt]': 'createdBefore',
              },
            },
          },
          {
            queryParams: ['infra_policy_enabled'],
            type: 'radio',
            label: this.$t('forms.status'),
            items: [
              {
                title: `${this.$t('all')}`,
                value: -1,
              },
              ...['disabled', 'enabled'].map((status) => ({
                title: `${this.$t(status)}`,
                value: status === 'disabled' ? 0 : 1,
              })),
            ],
          },
        ],
        sorts: [
          { sortBy: ['created_at'], sortDesc: [false] },
          { sortBy: ['created_at'], sortDesc: [true] },
          { sortBy: ['updated_at'], sortDesc: [true] },
          { sortBy: ['enabled'], sortDesc: [true] },
          { sortBy: ['enabled'], sortDesc: [false] },
          { sortBy: ['severity'], sortDesc: [false] },
          { sortBy: ['severity'], sortDesc: [true] },
          { sortBy: ['name'], sortDesc: [false] },
          { sortBy: ['name'], sortDesc: [true] },
        ],
      }
    },
    ...mapState('organization', {
      infraPolicies: (state) => state.available.infraPolicies,
    }),
    ...mapState('organization/infraPolicy', {
      errors: (state) => state.errors,
    }),
    hasBulkModeEnabled () {
      const canDelete = this.$cycloid.permissions.canDisplay('DeleteInfraPolicy')
      return canDelete && !_.isEmpty(this.infraPolicies)
    },
  },
  created () {
    this.updateQuery()
  },
  methods: {
    ...mapMutations('layout', [
      'SET_DATA_TABLE_FILTERS',
    ]),
    ...mapActions('organization', [
      'BULK_DELETE',
    ]),
    ...mapActions('organization/infraPolicy', [
      'UPDATE_INFRA_POLICY',
    ]),
    getLinkTarget ({ canonical: infraPolicyCanonical } = {}) {
      return {
        name: 'infraPolicy',
        params: { infraPolicyCanonical },
      }
    },
    async toggleInfraPolicyStatus ({ enabled, owner: { username }, ...infraPolicy }) {
      await this.UPDATE_INFRA_POLICY({ infraPolicy: { ...infraPolicy, enabled: !enabled, owner: username } })
      this.$refs.cyDataTable.retrieveItems({ clearErrors: false })
    },
    openDeleteModal (toDelete) {
      this.toDelete = toDelete
      this.$toggle.showDeleteModal(true)
    },
    closeDeleteModal () {
      this.$resetData('toDelete')
      this.$toggle.showDeleteModal(false)
    },
    async onDelete () {
      const { toDelete } = this
      await this.BULK_DELETE({ keyPath: 'infraPolicies', toDelete })
      this.$toggle.showDeleteModal()
      this.toDelete = []
      this.$refs.cyDataTable.retrieveItems({ clearErrors: false, clearSelected: true })
    },
    updateQuery () {
      const { name, query } = this.$route
      const filters = { ..._.omitBy(query, (val) => _.$isEmpty(val)) }
      const nowTimestamp = Date.now()
      const now = $date.format(nowTimestamp, 'yyyy-MM-dd')
      const sevenDaysAgo = $date.format($date.subDays(nowTimestamp, 7), 'yyyy-MM-dd')

      filters['infra_policy_created_at[gt]'] ||= sevenDaysAgo
      filters['infra_policy_created_at[lt]'] ||= now

      this.SET_DATA_TABLE_FILTERS({ name, filters })
      if (!_.isEqual(filters, query)) this.$router.replace({ query: filters }).catch(() => { /* silenced */ })
    },
    async assignNewOwner ({ formContent, owner }) {
      const infraPolicy = {
        ...formContent,
        owner: owner?.username,
      }
      const successMessage = this.$t('alerts.success.infraPolicy.reassigned', {
        infraPolicyName: formContent.name,
        owner: displayName(owner),
      })
      await this.UPDATE_INFRA_POLICY({ infraPolicy, successMessage })
      if (_.isEmpty(this.errors)) this.$refs.cyDataTable.retrieveItems()
    },
    setMissingOwners (items) {
      for (const item of items) item.owner = getMissingOwnerObject()
    },
    canGoToMember ({ owner }) {
      return !hasNoOwner(owner) && this.$cycloid.permissions.canDisplay('GetOrgMember', owner?.username)
    },
    canUpdateOwner ({ canonical, owner }) {
      return hasNoOwner(owner) && this.$cycloid.permissions.canDisplay('UpdateInfraPolicy', canonical)
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.infraPolicies',
        addInfraPolicyBtn: 'Add InfraPolicy',
        areYouSure: 'Are you really sure you want to delete the following @:untranslated.infraPolicy? | Are you really sure you want to delete the following @:untranslated.infraPolicies?',
        confirmDeleteTitle: '@:forms.btnDelete @:untranslated.infraPolicy | @:forms.btnDelete @:untranslated.infraPolicies',
        created: 'Created',
        deleteInfraPolicyBtn: '@:forms.btnDelete {nb} @:untranslated.infraPolicy | @:forms.btnDelete {nb} @:untranslated.infraPolicies',
      },
      es: {
        title: '@:routes.infraPolicies',
        addInfraPolicyBtn: 'Añadir InfraPolicy',
        areYouSure: '¿Está realmente seguro de que desea eliminar la siguiente @:untranslated.infraPolicy? | ¿Está realmente seguro de que desea eliminar las siguientes @:untranslated.infraPolicies?',
        confirmDeleteTitle: '@:forms.btnDelete @:untranslated.infraPolicy | @:forms.btnDelete @:untranslated.infraPolicies',
        created: 'Creado',
        deleteInfraPolicyBtn: '@:forms.btnDelete {nb} @:untranslated.infraPolicy | @:forms.btnDelete {nb} @:untranslated.infraPolicies',
      },
      fr: {
        title: '@:routes.infraPolicies',
        addInfraPolicyBtn: 'Ajouter InfraPolicy',
        areYouSure: `Êtes-vous vraiment sûr de vouloir supprimer l'@:untranslated.infraPolicy suivante ? | Êtes-vous vraiment sûr de vouloir supprimer les @:untranslated.infraPolicies suivantes ?`,
        confirmDeleteTitle: '@:forms.btnDelete une @:untranslated.infraPolicy | @:forms.btnDelete des @:untranslated.infraPolicies',
        created: 'Date de création',
        deleteInfraPolicyBtn: '@:forms.btnDelete {nb} @:untranslated.infraPolicy | @:forms.btnDelete {nb} @:untranslated.infraPolicies',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .cy-table-cmp-header {
  border-bottom: 1px solid cy-get-color("grey", "light-2");
}

::v-deep .v-table thead tr:first-child {
  border-bottom: 1px solid cy-get-color("grey", "light-2");
}

.infra-policies {
  &__item {
    flex-direction: column;

    &--critical {
      background-color: cy-get-color("error", "light-2") !important;
      color: cy-get-color("error", "dark-1");
    }

    &--warning {
      background-color: cy-get-color("warning", "light-2") !important;
      color: cy-get-color("warning", "dark-2");
    }

    &--advisory {
      background-color: cy-get-color("primary", "light-3") !important;
      color: cy-get-color("primary");
    }

    .v-chip {
      margin: 4px 0 !important;
      padding: 0 6px !important;
    }

    ::v-deep .v-list-item__action {
      width: 110px;

      .v-input--selection-controls__input {
        margin-right: 8px !important;
      }
    }
  }

  &__item-status {
    ::v-deep .router-link-wrapper {
      align-items: start;
      justify-content: end;
    }

    ::v-deep .v-input {
      margin: auto 0;
      text-align: right !important;
    }

    ::v-deep .v-input__slot {
      .v-label {
        color: cy-get-color("primary");
        font-size: 14px;
      }
    }
  }

  &__item-description {
    color: cy-get-color("primary", "light-2");
    font-size: 14px;
  }
}
</style>
